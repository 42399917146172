<template>
  <div class="content">
    <div class="headImg" @click="toIndex">
      <div>
        <img src="../assets/imgs/oneforone/headImg3.png" mode="widthFix" />
        <img src="../assets/imgs/oneforone/headImg1.png" mode="widthFix" />
      </div>
    </div>
    <div class="askWrap">
      <div class="title">学习遇瓶颈，<span>如何突围</span>？</div>
      <span class="dectitle">哪些孩子适合1对1课程</span>
      <div class="itemWrap">
        <div class="item" v-for="(item, index) in list" :key="index">
          {{ item }}
        </div>
      </div>
      <button class="button" @click="askBtn">立即咨询</button>
    </div>
    <div class="oneForone">
      <div class="title">1对1个性化<span>定制课</span></div>
      <span class="dectitle">不浪费孩子每一分钟</span>
      <div class="iconWrap">
        <div
          class="itemIcon"
          v-for="(item, index) in oneForList"
          :key="index"
          @mouseenter="changeIcon(index)"
          @mouseleave="leaveIcon(index)"
        >
          <img
            :src="
              require('../assets/imgs/oneforone/oneIcon' + item.name + '.png')
            "
            mode="widthFix"
          />
          <span>{{ item.title1 }}</span>
          <span>{{ item.title2 }}</span>
        </div>
      </div>
      <div class="gradeItem">
        <div class="item">
          <img src="../assets/imgs/oneforone/gradeImg_03.png" />
          <span>小学</span>
          <div class="itemMask">
            <span>基础巩固</span>
            <span>拓展拔高</span>
            <span>升学规划</span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/imgs/oneforone/gradeImg_05.png" />
          <span>初中</span>
          <div class="itemMask">
            <span>短板排查</span>
            <span>吃透考点</span>
            <span>直击中考</span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/imgs/oneforone/gradeImg_07.png" />
          <span>高中</span>
          <div class="itemMask">
            <span>签约提升</span>
            <span>锁定考点</span>
            <span>冲刺高考</span>
          </div>
        </div>
      </div>
      <button class="button" @click="askBtn">立即咨询</button>
    </div>
    <div class="fiveHeart">
      <div class="title">五大核心 让进步看得见</div>
      <div class="carWrap">
        <div class="carItem">
          <span>考情分析</span>
          <span
            >针对考点，解读考纲， 分析考情，让学习更 有方向，更有针对性。</span
          >
        </div>
        <div class="carItem">
          <span>考频统计</span>
          <span
            >用清晰明了的图标展 示考查频率，让学生 知道考什么，并锁定
            考点。</span
          >
        </div>
        <div class="carItem">
          <span>吃透考点</span>
          <span
            >根据考纲要求，将考点 系统化，让学生突破重
            难点，吃透核心考点。</span
          >
        </div>
        <div class="carItem">
          <span>方法突破</span>
          <span
            >掌握事半功倍的高效 学法，总结实用的备 考方法，短时间内快
            速提分。</span
          >
        </div>
        <div class="carItem">
          <span>积累巩固</span>
          <span
            >将所学知识进行系统 分类归纳，要点全面 条目清晰，方便查阅
            和记忆。</span
          >
        </div>
      </div>
      <button class="button" @click="askBtn">立即咨询</button>
    </div>
    <div class="sixLink">
      <div class="title"><span>六大</span>环节</div>
      <span class="dectitle">环环相扣、步步“精心”</span>
      <div class="imgWrap">
        <img src="../assets/imgs/oneforone/sixImg.png" mode="widthFix" />
        <img
          class="six sixIcon1"
          src="../assets/imgs/oneforone/six1.png"
          mode="widthFix"
        />
        <img
          class="six sixIcon2"
          src="../assets/imgs/oneforone/six2.png"
          mode="widthFix"
        />
        <img
          class="six sixIcon3"
          src="../assets/imgs/oneforone/six3.png"
          mode="widthFix"
        />
        <img
          class="six sixIcon4"
          src="../assets/imgs/oneforone/six4.png"
          mode="widthFix"
        />
        <img
          class="six sixIcon5"
          src="../assets/imgs/oneforone/six5.png"
          mode="widthFix"
        />
        <img
          class="six sixIcon6"
          src="../assets/imgs/oneforone/six6.png"
          mode="widthFix"
        />
      </div>
      <button class="button" @click="askBtn">立即咨询</button>
    </div>
    <div class="fourForone">
      <img src="../assets/imgs/oneforone/fourOneImg2.png" />
      <div class="title"><span>四对一</span> 为孩子学习保驾护航</div>
    </div>
    <div class="classWrap">
      <div class="classItem">
        <div class="classImgWrap">
          <img src="../assets/imgs/oneforone/classimg2.png" mode="widthFix" />
        </div>
        <div class="classmessage">
          <span>签约定制班</span>
          <span
            >1对1定制课程，结合孩子的情况量身定制教学方案，骨干教师精讲学习
            难点，精练考点。因材施教，找到孩子的薄弱点，逐一攻克，成绩快</span
          >
        </div>
      </div>
      <div class="classItem">
        <div class="classImgWrap">
          <img src="../assets/imgs/oneforone/classimg1.png" mode="widthFix" />
        </div>
        <div class="classmessage">
          <span>签约精英班</span>
          <span
            >3—6人互动式教学，学习成绩相近的学生，可互相交流讨论，实现优势
            互补。针对孩子学习问题，强化训练，挖掘孩子潜能，让成绩爆发式</span
          >
        </div>
      </div>
    </div>
    <div class="envImg">
      <div class="title">优美<span>教学环境</span></div>
      <span class="dectitle">让学习更舒心</span>
      <div class="envWrap">
        <div class="top">
          <img src="../assets/imgs/oneforone/envimg1.png" mode="widthFix" />
          <div>
            <img src="../assets/imgs/oneforone/envimg2.png" mode="widthFix" />
            <img src="../assets/imgs/oneforone/envimg3.png" mode="widthFix" />
          </div>
        </div>
        <div class="bottom">
          <img src="../assets/imgs/oneforone/envimg4.png" mode="widthFix" />
          <img src="../assets/imgs/oneforone/envimg5.png" mode="widthFix" />
          <img src="../assets/imgs/oneforone/envimg6.png" mode="widthFix" />
        </div>
      </div>
    </div>
    <div class="writeForm">
      <div class="formCard">
        <span>免费获取体验课</span>
        <div class="formWrap">
          <div>
            <input
              type="text"
              v-model="form.userName"
              placeholder="姓名"
              maxlength="6"
            />
            <input
              type="text"
              v-model="form.userPhone"
              placeholder="请输入您的手机号"
              maxlength="11"
            />
          </div>
          <div>
            <div class="grade">
              <div @click="openpopup" class="sec">
                <input
                  type="text"
                  disabled
                  v-model="form.message"
                  placeholder="年级"
                />
                <span>></span>
              </div>

              <div class="selectGrade" v-show="isSelect == true">
                <span
                  v-for="(item, index) in gradeList"
                  :key="index"
                  @click="chooseGrade(item)"
                  >{{ item }}</span
                >
              </div>
            </div>
            <div class="code">
              <input
                type="text"
                v-model="form.code"
                placeholder="请输入验证码"
                maxlength="6"
              />
              <span v-if="is_click" @click="getCode">{{ timerspan }}</span>
              <span v-else>{{ timerspan }} {{ count }}</span>
            </div>
          </div>
        </div>
        <button class="button" @click="submitForm">立即领取</button>
        <div class="rule">
          <div class="spanWrap">
            <span>我已阅读并同意</span>
            <div @click="clickLink">《望子成龙用户注册协议》</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSms, submitUserInfo } from "@/api/api.js";
// import api from "../api/api.js"
export default {
  data() {
    return {
      isSelect: false, //选择年级
      //提交的表单数据
      form: {
        userName: "",
        userphone: "",
        message: "",
        code: "",
        comeFrom: "一对一",
      },
      oneForList: [
        {
          name: "1",
          title1: "1对1方案定制",
          title2: "看失分 不遗漏",
        },
        {
          name: "2",
          title1: "1对1陪读答疑",
          title2: "练思维 提能力",
        },
        {
          name: "3",
          title1: "1对1授课指导",
          title2: "学方法 找技巧",
        },
        {
          name: "4",
          title1: "1对1升学规划",
          title2: "拟规划 提分准",
        },
      ],
      timerspan: "获取验证码", //定时数字
      count: 60, //计时器
      is_click: true, //是否开始计时
      gradeList: [
        "一年级",
        "二年级",
        "三年级",
        "四年级",
        "五年级",
        "六年级",
        "初一",
        "初二",
        "初三",
        "高一",
        "高二",
        "高三",
      ],
      list: [
        "基础薄弱，跟不上",
        "优生拔高，与瓶颈",
        "中等突围，学不精",
        "偏科厌学，没动力",
        "沉迷手机，没兴趣",
        "习惯不好，心态差",
        "若学无效，没技巧",
        "冲刺明校，太焦虑",
      ],
      phoneRule: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
    };
  },

  methods: {
    openpopup() {
      this.isSelect = true;
    },
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
    //改变图标
    changeIcon(index) {
      this.oneForList[index].name = "F" + (index + 1);
    },
    //离开图标
    leaveIcon(index) {
      this.oneForList[index].name = index + 1;
    },
    //验证表单
    verifica() {
      let phoneRule = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      return phoneRule.test(this.form);
    },
    //获取验证码
    getCode() {
      if (this.form.userName && this.form.userPhone && this.form.message) {
        this.timerReg();
        sendSms({
          phone: this.form.userPhone,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
          }
          if (!res.data.data) {
            this.$message({
              type: "warning",
              message: res.data.message,
            });
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请填写姓名 手机号 年级",
        });
      }
    },

    //页面验证计时器
    timerReg() {
      let that = this;
      that.timerspan = "已发送验证码";
      that.is_click = false;
      var timer = setInterval(function () {
        that.count--;
        if (that.count < 1) {
          clearInterval(timer);
          that.timerspan = "获取验证码";
          that.count = 60;
          that.is_click = true;
          return;
        }
      }, 1000);
    },
    //选择年级
    chooseGrade(item) {
      this.form.message = item;
      this.isSelect = false;
    },
    //提交表单
    submitForm() {
      if (!this.phoneRule.test(this.form.userPhone)) {
        this.$message({
          type: "warning",
          message: "手机号错误，请重新填写",
        });
        return;
      }
      if (
        this.form.userName &&
        this.form.userPhone &&
        this.form.message &&
        this.form.code
      ) {
        submitUserInfo(this.form).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "获取成功",
            });
            this.form = {
              userName: "",
              userphone: "",
              message: "",
              code: "",
              comeFrom: "一对一",
            };
          } else {
            this.$message({
              type: "warning",
              message: "获取失败",
            });
          }
        });
      } else if (!this.form.code) {
        this.$message({
          type: "warning",
          message: "请填写验证码",
        });
      }
    },
    //立即咨询
    askBtn() {
      location.href = "https://tb.53kf.com/code/client/10179248/1";
    },
    //链接跳转
    clickLink() {
      window.location.href = "http://app.wzclzx.com/agreement.html";
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

.headImg {
  width: 100%;
  height: 496px;
  background-image: url(~@/assets/imgs/oneforone/headImg2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  div {
    width: 1192px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  img:first-child {
    width: 152px;
    position: absolute;
    top: 26px;
    left: 0;
  }
  img:last-child {
    width: 1076px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-40%, 0);
  }
}

.askWrap {
  // /height:calc(100vw * 0.49);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(~@/assets/imgs/oneforone/oneImg2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  .button {
    margin-bottom: 50px;
  }
  .itemWrap {
    width: 750px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-content: flex-start;
    font-size: 24px;
    margin-top: 100px;

    .item {
      width: 250px;
      height: 40px;
      line-height: 40px;
      padding: 8px 26px;
      text-align: center;
      border-radius: 30px;
      color: #595959;
      border: 1px solid #7d7d7d;
      margin-bottom: 20px;
    }

    .item:hover {
      animation: icon-bounce 0.5s infinite;
    }

    //抖动
    @keyframes icon-bounce {
      0%,
      100% {
        transform: rotate(0deg);
      }

      25% {
        transform: rotate(4deg);
      }

      50% {
        transform: rotate(-4deg);
      }

      75% {
        transform: rotate(2deg);
      }

      85% {
        transform: rotate(-2deg);
      }
    }
  }
}

.title {
  min-width: 606px;
  font-size: 42px;
  font-weight: 600;
  margin-top: 98px;
  display: flex;
  justify-content: center;

  span {
    color: #d6000f;
  }
}

.dectitle {
  min-width: 606px;
  font-size: 24px;
  color: #4d4d4d;
  text-align: center;
  margin-top: 33px;
}

.button {
  width: 332px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #fe5d1d;
  color: #fff;
  font-size: 24px;
  border-radius: 20px;
  margin-top: 62px;
  box-shadow: 0px 4px 10px 0px rgba(255, 84, 0, 1);
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  background-color: #ff7e00;
}

button:after {
  border: none;
}
button {
  outline: none;
  border: none;
}

//一对一定制
.oneForone {
  height: 1308px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 160px;
  }
  .iconWrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-top: 112px;
  }

  .itemIcon {
    width: 274px;
    height: 226px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    box-shadow: 0px 0px 8px #c1c1c1;
    font-size: 24px;
    font-weight: 600;
    color: #323232;
    transition: all 0.1s;
    img {
      width: 64px;
      margin-bottom: 20px;
    }

    span:last-child {
      font-size: 20px;
      margin-top: 9px;
    }
  }

  .itemIcon:hover {
    background-image: linear-gradient(to bottom, #fe5c1c, #d60210);
    color: #fff;
  }

  .gradeItem {
    width: 1200px;
    height: 358px;
    display: flex;
    justify-content: space-between;
    margin-top: 66px;
    font-size: 20px;
    color: #2f2f2f;

    .item {
      width: 394px;
      height: 100%;
      position: relative;
      img {
        width: 394px;
        height: 296px;
      }
    }
    .itemMask {
      width: 100%;
      height: 296px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 22px;
      background: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      transition: all 0.6s ease;
    }

    .item:hover .itemMask {
      opacity: 1;
    }

    span {
      display: block;
      text-align: center;
      margin-top: 18px;
    }
  }
}

//五大核心
.fiveHeart {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/imgs/oneforone/bimg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  .title {
    margin-top: 52px;
  }
  .button {
    margin-bottom: 84px;
  }
  .carWrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin-top: 88px;

    .carItem {
      width: 230px;
      height: 290px;
      background-color: #fff;
      border-radius: 20px;
      padding: 46px 18px 26px 18px;
      box-sizing: border-box;
      box-shadow: 0px 0px 8px #c1c1c1;
      transition: all 0.3s;

      span:first-child {
        display: block;
        font-weight: 600;
        font-size: 26px;
        text-align: center;
        margin-bottom: 20px;
      }

      span:last-child {
        display: block;
        font-size: 18px;
        line-height: 40px;
        text-align: justify;
        color: #535353;
      }
    }

    .carItem:hover {
      transform: scale(1.08);
    }
  }
}

.sixLink {
  // /height: 813px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .title {
    margin-top: 102px;
  }
  .button {
    margin-bottom: 106px;
  }
  .imgWrap {
    width: 1200px;
    height: 536px;
    margin-top: 60px;
    position: relative;
    img:first-child {
      width: 426px;
      display: block;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .six {
      width: 266px;
    }

    .sixIcon1 {
      position: absolute;
      top: 0;
      left: 40px;
      animation: simg1 3s linear infinite alternate;
    }

    .sixIcon2 {
      position: absolute;
      top: 0;
      right: 40px;
      animation: simg2 2s linear infinite alternate;
    }

    .sixIcon3 {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
      animation: simg3 3s linear infinite alternate;
    }

    .sixIcon4 {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(0, -50%);
      animation: simg4 2.5s linear infinite alternate;
    }

    .sixIcon5 {
      position: absolute;
      bottom: 0;
      left: 40px;
      animation: simg5 2s linear infinite alternate;
    }

    .sixIcon6 {
      position: absolute;
      bottom: 0;
      right: 40px;
      animation: simg6 2.5s linear infinite alternate;
    }

    @keyframes simg1 {
      from {
        left: 65px;
        top: 20px;
      }

      to {
        left: 85px;
        top: 20px;
      }
    }

    @keyframes simg2 {
      from {
        top: 20px;
        right: 65px;
      }

      to {
        top: 20px;
        right: 85px;
      }
    }

    @keyframes simg3 {
      from {
        top: 270px;
        left: 0px;
      }

      to {
        top: 270px;
        left: 20px;
      }
    }

    @keyframes simg4 {
      from {
        top: 270px;
        right: 0px;
      }

      to {
        top: 270px;
        right: 20px;
      }
    }

    @keyframes simg5 {
      from {
        bottom: 20px;
        left: 65px;
      }

      to {
        bottom: 20px;
        left: 85px;
      }
    }

    @keyframes simg6 {
      from {
        bottom: 20px;
        right: 65px;
      }

      to {
        bottom: 20px;
        right: 85px;
      }
    }
  }
}

.fourForone {
  height: 732px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(../assets/imgs/oneforone/fourOneImg.png);
  background-repeat: no-repeat;
  background-size: fixed;
  background-position: center center;
  img {
    width: 1032px;
    height: 511px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .title {
    margin-top: 40px;
  }
}

.classWrap {
  width: 1200px;
  //height: 590px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 30px;

  .classItem {
    width: 590px;
    height: 590px;
    box-shadow: 0px 0px 8px #ccc;

    img {
      width: 100%;
      display: block;
      transition: transform 0.3s;
    }

    .classmessage {
      padding: 26px 20px;
      span:first-child {
        display: block;
        font-size: 24px;
        font-weight: 600;
        color: #d00000;
        margin-bottom: 15px;
      }

      span:last-child {
        display: block;
        font-size: 18px;
        color: #414141;
        line-height: 38px;
        text-align: justify;
      }
    }
  }

  .classImgWrap {
    width: 100%;
    overflow: hidden;
  }

  .classImgWrap img:hover {
    transform: scale(1.2);
  }
}

.envImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  .envWrap {
    width: 1200px;
    margin-top: 68px;
    img {
      box-shadow: 0px 15px 12px -15px #000;
    }
    .top {
      display: flex;
      justify-content: space-between;

      img {
        width: 648px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          width: 530px;
        }
      }
    }

    .bottom {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      img {
        width: 388px;
      }
    }

    img {
      transition: all 0.3s;
    }

    img:hover {
      transform: scale(1.03);
    }
  }
}

.writeForm {
  height: 512px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .formCard {
    width: 1200px;
    height: 445px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 12px #ccc;
    border-radius: 30px;
    margin-top: 50px;

    > span {
      font-size: 28px;
      font-weight: 600;
      color: #2c2c2c;
      margin-top: 42px;
      margin-bottom: 36px;
    }

    .formWrap {
      width: 690px;

      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      input {
        width: 304px;
        height: 44px;
        border: 1px solid #ccc;
        font-size: 16px;
        padding-left: 16px;
        border-radius: 20px;
        outline: none;
      }

      .grade {
        position: relative;
        .sec {
          position: relative;
          > span {
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 16px;
            color: #ccc;
          }
        }

        .selectGrade {
          width: 100%;
          height: 150px;
          overflow-y: scroll;
          position: absolute;
          left: 0px;
          top: calc(100% + 10px);
          z-index: 10;
          background-color: #fff;

          span {
            display: block;
            height: 30px;
            line-height: 30px;
            color: #333;
            font-size: 13px;
            padding-left: 20px;
          }

          span:hover {
            background-color: #ccc;
          }
        }
      }

      .code {
        height: 44px;
        position: relative;

        span {
          display: block;
          padding: 0 16px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 16px;
          border-left: 1px solid #ccc;
          color: #fea623;
        }
      }
    }

    .button {
      width: 302px;
      height: 60px;
      line-height: 60px;
      border-radius: 30px;
      margin-top: 40px;
      font-size: 22px;
      box-shadow: 0px 15px 12px -15px #ff5500;
    }

    .rule {
      font-size: 12px;
      color: #666;
      margin-top: 30px;

      .spanWrap {
        display: flex;

        div {
          color: #ff4122;
        }
      }
    }
  }
}
</style>
